import { useAppDispatch } from '../../../app/hooks';
import { useSelector } from 'react-redux';
import { selectorAuthWebRequest } from '../../../app-view/auth-web';
import FormSignup from '../../organism/FormSignup';
import { SignupPayload } from '../../../app/api/types';
import { signupPostTrigger } from '../../../app/api/actions';

interface Prop {
    register?: (payload: SignupPayload) => void
}

function Register({ register }: Prop) {
    const dispatch = useAppDispatch()
    const authWeb = useSelector(selectorAuthWebRequest)

    const handleSignup = (payload: SignupPayload) => {
        dispatch(signupPostTrigger(payload))
        register?.(payload)
    }
    return (
        <>
            <FormSignup onClick={handleSignup}></FormSignup>
        </>
    );
}

export default Register;
