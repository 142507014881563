import { Action, CombinedState } from "@reduxjs/toolkit";
import { StateObservable } from "redux-observable";
import { from, interval, merge, Observable, of, Subject } from "rxjs";
import { map, filter, withLatestFrom, switchMap, catchError, mergeMap, takeUntil, tap, take, mapTo, delay } from "rxjs/operators";
import { 
  callApiStart, 
  configEndpoint, 
  signupPartnerFailed, signupPartnerProcessed, signupPartnerTrigger, 
  signupPartnerGetFailed, signupPartnerGetProcessed, signupPartnerGetTrigger, 
  profilePaymentFailed, profilePaymentProcessed, profilePaymentTrigger, 
  partnerGetTrigger, partnerGetProcessed, partnerGetFailed, 
  m2mAuthorizeTrigger, m2mAuthorizeFailed, m2mAuthorizeProcessed, 
  notificationGetTrigger, notificationGetProcessed, notificationGetFailed,
  notificationPostTrigger, notificationPostFailed, notificationPostProcessed, 
  configureGetTrigger, configureGetProcessed, configureGetFailed, 
  documentGetTrigger, documentGetFailed, documentGetProcessed, 
  documentUserGetTrigger, documentUserGetProcessed, documentUserGetFailed,
  documentUserPatchTrigger, documentUserPatchFailed, documentUserPatchProcessed,
  documentPostTrigger, documentPostProcessed, documentPostFailed,
  documentUserDeleteFailed, documentUserDeleteProcessed, documentUserDeleteTrigger,
  apiToken, logout,
  oidcRedirectTrigger,
  oidcRedirectProcessed,
  oidcRedirectFailed,
  authorizeGetTrigger,
  authorizeGetProcessed,
  authorizeGetFailed,
  authorizePostTrigger,
  authorizePostProcessed,
  authorizePostFailed,
  signupPostTrigger,
  signupPostProcessed,
  signupPostFailed,
  confirmPostTrigger,
  confirmPostProcessed,
  confirmPostFailed,
  enrollGetTrigger,
  enrollGetProcessed,
  enrollGetFailed,
  enrollPostTrigger,
  enrollPostProcessed,
  enrollPostFailed,
  oidcRedirectPostTrigger,
  oidcRedirectPostProcessed,
  oidcRedirectPostFailed, 
} from "./actions";
import { APIStore } from "./store";
import { RootState } from "../store";
import queryString from 'query-string';

const fetchTimeout = (url: string, options: RequestInit, timeout = 40000): Promise<Response> => {
  return Promise.race([
    fetch(url, options),
    new Promise<Response>((_, reject) =>
      setTimeout(() => reject(new Error('timeout')), timeout)
    )
  ]);
}

export const fetchData = ({ api, path, method, body, auth, refresh, ignoreResponse }: { api: string, path: string, method: string, body?: any, auth?: string, refresh?: string, ignoreResponse: boolean }) => {
  const headers: HeadersInit = auth ? {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${auth}`,
  } : {
    "Content-Type": "application/json",
  }
  const refreshToken = `${api}account/token/refresh/`
  const url = `${api}${path.replace(/^\//,"")}`
  return fetchTimeout(`${url}`, {
    method: method,
    headers: headers,
    body: body,
  })
    .then(response => {
      if (!response.ok) {
        if (response.status === 401 && auth && refreshToken !== url) {
          return fetchTimeout(`${refreshToken}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ refresh: refresh }),
          }).then(response => {
            if (!response.ok) {
              return response.json().then(json => { throw { http_status: response.status, ...json } })
            }
            if (response.status === 204) {
              return {}
            }
            return response.json();
          })
        }
        if(ignoreResponse) throw {}
        return response.json().then(json => {
          throw json;
        });
      }
      if (response.status === 204 || ignoreResponse) {
        return {}
      }
      return response.json();
    });
}

export const callApiProcessing = (
  action$: Observable<Action>,
  state$: StateObservable<CombinedState<{ api: APIStore; }>>
): Observable<Action> =>
  action$.pipe(
    filter(callApiStart.match),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      from(fetchData({
        ...action.payload,
        auth: action.payload.auth ? state.api.token?.access_token : undefined,
        refresh: action.payload.auth ? state.api.token?.refresh_token : undefined,
        ignoreResponse: !!action.payload.ignoreResponse
      })).pipe(
        map(response => action.payload.success(response)),
        catchError(error => {
          if (error.http_status === 401) {
            return of(logout(), action.payload.reject(error))
          }
          return of(action.payload.reject(error))
        })
      ))
  );

export const epicConfigEndpoint = (action$: Observable<Action>, state$: Observable<APIStore>) =>
  action$.pipe(
    filter(configureGetProcessed.match),
    withLatestFrom(state$),
    map(([action, state]) => configEndpoint({ environments: action.payload.environments })));

export const epicSignupPartnerGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(signupPartnerGetTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.partnerSignup}?email=${action.payload.email}`,
        method: "GET",
        success: signupPartnerGetProcessed,
        reject: signupPartnerGetFailed,
        auth: false,
      })
    })
  );

export const epicSignupTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(signupPostTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.signup}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: signupPostProcessed,
              reject: signupPostFailed,
              auth: false,
          })
      })
  );

export const epicConfirmTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(confirmPostTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.confirm}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: confirmPostProcessed,
              reject: confirmPostFailed,
              auth: false,
          })
      })
  );
  
export const epicConfirmPost = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(confirmPostProcessed.match),
      mergeMap(action =>
      of(
        apiToken(action.payload),
      ))
  );
  
export const epicEnrollGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(enrollGetTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.enroll}?${queryString.stringify(action.payload)}`,
              method: "GET",
              success: enrollGetProcessed,
              reject: enrollGetFailed,
              auth: true,
          })
      })
  );


export const epicEnrollPostTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
      filter(enrollPostTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
          return callApiStart({
              api: `${state.api.api!.endpoint}`,
              path: `${state.api.api!.enroll}`,
              method: "POST",
              body: JSON.stringify({ ...action.payload }),
              success: enrollPostProcessed,
              reject: enrollPostFailed,
              auth: true,
          })
      })
  );

     
export const epicConfigureGetTriggerTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(configureGetTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `https://api.voiceme-production.demo.infra.voiceme.id/`,
        path: `/voiceme/configuration/?search=${action.payload.config}`,
        method: "GET",
        success: configureGetProcessed,
        reject: configureGetFailed,
        auth: false,
      })
    })
  );

export const epicSignupPartnerTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(signupPartnerTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.partnerSignup}`,
        method: "POST",
        body: JSON.stringify({ ...action.payload }),
        success: signupPartnerProcessed,
        reject: signupPartnerFailed,
        auth: false,
      })
    })
  );

export const epicSignupPartnerPaymentUrlTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(profilePaymentTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.profilePayment}`,
        method: "POST",
        body: JSON.stringify({ ...action.payload }),
        success: profilePaymentProcessed,
        reject: profilePaymentFailed,
        auth: true,
      })
    })
  );

export const epicProfilePaymentGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(partnerGetTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.partner}`,
        method: "GET",
        success: partnerGetProcessed,
        reject: partnerGetFailed,
        auth: true,
      })
    })
  );

export const epicM2mAuthorizeTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(m2mAuthorizeTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.m2mAuthorize}`,
        method: "POST",
        body: JSON.stringify({ ...action.payload }),
        success: m2mAuthorizeProcessed,
        reject: m2mAuthorizeFailed,
        auth: false,
      })
    })
  );

export const epicM2mAuthorizeProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(m2mAuthorizeProcessed.match),
    mergeMap(action =>
      of(
        apiToken(action.payload),
      ))
  );

export const epicNotificationGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(notificationGetTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.notifications}?session_id=${action.payload.session_id}${action.payload.secret ? '&secret=' + action.payload.secret : ""}`,
        method: "GET",
        success: notificationGetProcessed,
        reject: notificationGetFailed,
        auth: false,
      })
    })
  );

export const epicNotificationPostTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(notificationPostTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: `${state.api.api!.notifications}`,
        method: "POST",
        body: JSON.stringify({ ...action.payload }),
        success: notificationPostProcessed,
        reject: notificationPostFailed,
        auth: false,
      })
    })
  );

export const epicNotificationProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(notificationPostProcessed.match),
    switchMap(action => {
      const stopAll = new Subject();
      return merge(
        interval(1500).pipe(
          mapTo(notificationGetTrigger({ session_id: action.payload.session_id })),
          takeUntil(stopAll),
        ),
        action$.pipe(
          filter(notificationGetProcessed.match),
          filter(a => a.payload.session_id === action.payload.session_id),
          filter(a => a.payload.status === 'SUCCESS'),
          filter(a => a.payload.token === undefined),
          map(_a => notificationGetTrigger({
            session_id: action.payload.session_id,
            secret: action.payload.secret
          })),
          take(1),
        ),
        action$.pipe(
          filter(notificationGetFailed.match),
          tap(_ => stopAll.next()),
          take(1),
        ),
        action$.pipe(
          filter(notificationGetProcessed.match),
          filter(a => a.payload.session_id === action.payload.session_id),
          filter(a => a.payload.status === 'FAILED'),
          take(1),
          tap(_ => stopAll.next()),
          map(a => notificationGetFailed({code: "AuthFailed", detail: "Session expired or to many retry"}))
        ),
        action$.pipe(
          filter(notificationGetProcessed.match),
          filter(a => a.payload.session_id === action.payload.session_id),
          filter(a => a.payload.status === 'SUCCESS'),
          filter(a => a.payload.token !== undefined),
          map(a => apiToken(a.payload.token!)),
          tap(a => stopAll.next()),
          take(1),
        ),
      )
    }),
  );

export const epicNotificationAfterSign = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentUserPatchProcessed.match),
    switchMap(action => {
      const stopAll = new Subject();
      return merge(
        interval(1500).pipe(
          mapTo(notificationGetTrigger({ session_id: action.payload.session_id })),
          takeUntil(stopAll),
        ),
        action$.pipe(
          filter(notificationGetProcessed.match),
          filter(a => a.payload.session_id === action.payload.session_id),
          filter(a => a.payload.status === 'SUCCESS'),
          filter(a => a.payload.token === undefined),
          map(_a => notificationGetTrigger({
            session_id: action.payload.session_id,
            secret: action.payload.secret
          })
          ),
          take(1),
        ),
        action$.pipe(
          filter(notificationGetFailed.match),
          tap(_ => stopAll.next()),
          take(1),
        ),
        action$.pipe(
          filter(notificationGetProcessed.match),
          filter(a => a.payload.session_id === action.payload.session_id),
          filter(a => a.payload.status === 'SUCCESS'),
          filter(a => a.payload.token !== undefined),
          delay(2000),
          map(a => documentUserGetTrigger({id: action.payload.id})),
          tap(a => stopAll.next()),
          take(1),
        ),
      )
    }),
  );

export const epicDocumentGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentGetTrigger.match),
    switchMap(action =>
      state$.pipe(
        filter(state => !!state.api.api),
        take(1),
        delay(1),
        map(state => callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.document}`,
          method: "GET",
          success: documentGetProcessed,
          reject: documentGetFailed,
          auth: true,
        })),
      )));

export const epicDocumentUserGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentUserGetTrigger.match),
    switchMap(action =>
      state$.pipe(
        filter(state => !!state.api.api),
        take(1),
        delay(1),
        map(state => callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.document}${action.payload.id}/`,
          method: "GET",
          success: documentUserGetProcessed,
          reject: documentUserGetFailed,
          auth: true,
        })),
      )));

export const epicDocumentUserPatchTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentUserPatchTrigger.match),
    switchMap(action =>
      state$.pipe(
        filter(state => !!state.api.api),
        take(1),
        delay(1),
        map(state => callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.document}${action.payload.id}/`,
          method: "PATCH",
          success: documentUserPatchProcessed,
          reject: documentUserPatchFailed,
          auth: true,
          body: JSON.stringify({ id: action.payload.id, sign: action.payload.sign, service_id: action.payload.service_id }),
        })),
      )));

export const epicDocumentPostTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentPostTrigger.match),
    switchMap(action =>
      state$.pipe(
        filter(state => !!state.api.api),
        take(1),
        delay(1),
        map(state => callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.document}`,
          method: "POST",
          success: documentPostProcessed,
          reject: documentPostFailed,
          auth: true,
          body: JSON.stringify({ document: action.payload.document, file_name: action.payload.fileName }),
        })),
      )));

export const epicDocumentPostProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentPostProcessed.match),
    map(action => documentGetTrigger()));

export const epicDocumentUserDeleteTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentUserDeleteTrigger.match),
    switchMap(action =>
      state$.pipe(
        filter(state => !!state.api.api),
        take(1),
        delay(1),
        map(state => callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.document}${action.payload}/`,
          method: "DELETE",
          success: documentUserDeleteProcessed,
          reject: documentUserDeleteFailed,
          auth: true,
        })),
      )));

export const epicDocumentUserDeleteProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(documentUserDeleteProcessed.match),
    map(action => documentGetTrigger()));

export const epicOidcRedirectTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(oidcRedirectTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: action.payload.next ? `${action.payload.next}` : `${state.api.api!.oidc}?${queryString.stringify(action.payload)}`,
        method: "GET",
        success: oidcRedirectProcessed,
        reject: oidcRedirectFailed,
        auth: true,
        ignoreResponse: false,
      })
    })
  );

export const epicOidcRedirectPostTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
  action$.pipe(
    filter(oidcRedirectPostTrigger.match),
    withLatestFrom(state$),
    filter(([, state]) => !!state.api.api),
    map(([action, state]) => {
      return callApiStart({
        api: `${state.api.api!.endpoint}`,
        path: action.payload.next ? `${action.payload.next}&allow=true` : `${state.api.api!.oidc}?${queryString.stringify(action.payload)}`,
        method: "POST",
        success: oidcRedirectPostProcessed,
        reject: oidcRedirectPostFailed,
        auth: true,
        ignoreResponse: true,
      })
    })
  );
  
  export const epicAuthorizeGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
      filter(authorizeGetTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
        return callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.authorize}?${queryString.stringify(action.payload)}`,
          method: "GET",
          success: authorizeGetProcessed,
          reject: authorizeGetFailed,
          auth: false,
        })
      })
    );
  
  export const epicAuthorizePostTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
      filter(authorizePostTrigger.match),
      withLatestFrom(state$),
      filter(([, state]) => !!state.api.api),
      map(([action, state]) => {
        return callApiStart({
          api: `${state.api.api!.endpoint}`,
          path: `${state.api.api!.authorize}`,
          method: "POST",
          body: JSON.stringify({ ...action.payload }),
          success: authorizePostProcessed,
          reject: authorizePostFailed,
          auth: false,
        })
      })
    );
  